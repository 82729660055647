import { render, staticRenderFns } from "./newPassword.vue?vue&type=template&id=65847a51&"
import script from "./newPassword.vue?vue&type=script&lang=js&"
export * from "./newPassword.vue?vue&type=script&lang=js&"
import style0 from "@/styles/components/login/input.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "@/styles/components/login/index.scss?vue&type=style&index=1&prod&rel=stylesheet%2Fscss&lang=scss&"
import style2 from "./newPassword.vue?vue&type=style&index=2&id=65847a51&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports